const { localizePath } = require('../config/i18n')
/**
 * Configure and localize specific urls/routes for
 * Prismic content types below. The content type key
 * must match the content type/file name for the
 * content type's json schema file.
 */
module.exports = ({ node, key, value }) => (doc) => {
  const typesMap = {
    article: () => `/news/${doc.uid}`,
    blockchain_101: () => `/technology/about-blockchain`,
    careers: () => `/about/careers`,
    contact_page: () => `/contact`,
    content_page: () => `/${doc.uid}`,
    developer_resources: () => `/ecosystem/developer-resources`,
    economic_innovation_article: () => `/publications/${doc.uid}`,
    // TODO: I don't think this is used. No content in Prismic exists for the type. Potentially remove.
    // economic_innovation:
    //   () => ``,
    enterprise: () => `/contact/enterprise`,
    /**
     * The Event content type does not have its own template so
     * we don't need to route to yet.
     */
    // event:
    //   () => `/ecosystem/events/${ doc.uid }`,
    events: () => `/ecosystem/events`,
    faq: () => `/technology/faq`,
    homepage_v6: () => `/`,
    media_kit: () => `/about/media-kit`,
    // TODO: Remove this after we go live with new news page. also remove news content type from prismic
    news: () => `/news`,
    newsletter: () => `/contact/newsletter`,
    our_founder: () => `/our-founder`,
    our_history: () => `/about/our-history`,
    our_partners: () => `/about/our-partners`,
    page: () => {
      // replace _ with /
      return `/${doc.uid.replace(/_/g, '/')}`
    },
    research_innovation: () => `/publications`,
    // TODO: possibly delete these and content type after launching new update
    resource_page: () => `/resources/${doc.uid}`,
    section_landing: () => `/${doc.uid}`,
    security: () => `/contact/security`,
    task_spend_some_money: () => `/task-spend-some-money`,
    team: () => `/our-team`,
    technical_advantages: () => `/technology/technical-advantages`,
    tech_core_blockchain_innovation: () =>
      `/technology/core-blockchain-innovation`,
    tech_our_approach: () => `/technology/algorand-protocol`,
    technology: () => `/technology`,
    technology_sub_page: () => `/technology/${doc.uid}`,
    testnet_tasks_telemetry_registration: () =>
      `/testnet-tasks-telemetry-registration`,
    the_community: () => `/ecosystem/community`,
    use_case: () => `/ecosystem/use-cases/${doc.uid}`,
    use_cases: () => `/ecosystem/use-cases`,
    videos: () => `/resources/videos`,
    whitepaper: () => `/technology/white-papers`,
    why_build_on_algorand: () => `/why-build-on-algorand`,
    resource_archive_blog: () => '/resources/blog',
    resource_archive_algo: () => '/resources/algorand-announcements',
    resource_archive_eco: () => '/resources/ecosystem-announcements',
    resource_archive_news: () => '/resources/news',
    resource_landing: () => '/resources',
    featured_page: () => `/${doc.uid}`,
    team_research_papers: () => '/peer-reviewed-papers',
    standards_proposals: () => '/standards-proposals',
  }

  /**
   * Algorand Foundation page is of content_page type. The file
   * is in pages/about/algorand-foundation. We need to look for
   * it and set the url in order for it to generate proper url
   * when selected in Prismic as a page link.
   */
  if (doc.uid === 'algorand-foundation') {
    return localizePath(doc.lang, '/about/algorand-foundation')
  }

  // if (doc.uid === 'partners') {
  //   return localizePath(doc.lang, '/ecosystem/partners')
  // }

  return typesMap[doc.type]
    ? localizePath(doc.lang, typesMap[doc.type]())
    : doc.uid
}
