// import React from 'react'
// import { navigate } from 'gatsby'
// import { usePrismicPreview } from 'gatsby-source-prismic'
// import WhitePaperTemplate from './technology/white-papers'
// import FaqTemplate from './technology/faq'
// import OurTeamTemplate from './about/our-team'
// // import OurHistoryTemplate from './about/our-history'
// // import ArticleTemplate from '../templates/article'
// // import SectionLandingTemplate from '../templates/section-landing-temp'
// // import AlgorandFoundationTemplate from './about/algorand-foundation'
// import OurPartnersTemplate from './about/our-partners'
// // import OurFounderTemplate from './about/from-our-founder'
// import PageTemplate from '../templates/page'
// import CareersTemplate from './about/careers'
// import CommunityTemplate from './ecosystem/community'
// import SecurityTemplate from './contact/security'
// import VideosTemplate from './resources/videos'
// import AboutBlockchainTemplate from './technology/about-blockchain'
// import EconomicInnovationArticleTemplate from '../templates/economic-innovation-article'
// import BlockchainInnovationTemplate from './technology/core-blockchain-innovation'
// import TechnologySubPageTemplate from '../templates/technology-sub-page'
// import TechnologyPageTemplate from '../pages/technology'
// import OurApproachTemplate from '../pages/technology/algorand-protocol'
// import TechnologyAdvantagesTemplate from '../pages/technology/technical-advantages'
// import HomepageTemplate from './index'
// import ContactUsTemplate from './contact'
// import EnterpriseContactTemplate from './contact/enterprise'
// import NewsletterSignupTemplate from './contact/newsletter'
// import WhyBuildOnAlgorandTemplate from './why-build-on-algorand'
// import TestnetTasksTemplate from './testnet-tasks-telemetry-registration'
// import TaskSpendTemplate from './task-spend-some-money'
// import UseCaseTemplate from '../templates/useCase'
// import MediaKit from './about/media-kit'

// const PreviewPage = (props) => {
//   const { location } = props

//   const data = usePrismicPreview(location, { repositoryName: 'algorandcom' })
//   const { previewData } = data

//   if (typeof window !== 'undefined') {
//     window.__PRISMIC_PREVIEW_DATA__ = previewData
//   }

//   if (!previewData) {
//     return <div>Loading preview...</div>
//   }
//   if (previewData.prismicWhitepaper) {
//     return <WhitePaperTemplate {...props} />
//   } else if (previewData.prismicFaq) {
//     return <FaqTemplate {...props} />
//   } else if (previewData.prismicTeam) {
//     return <OurTeamTemplate {...props} />
//   } else if (previewData.prismicOurPartners) {
//     return <OurPartnersTemplate {...props} />
//   } else if (previewData.prismicPage) {
//     return <PageTemplate {...props} />
//     // prismicCareers
//   } else if (previewData.prismicCareers) {
//     return <CareersTemplate {...props} />
//   } else if (previewData.prismicEvents) {
//     setTimeout(() => {
//       navigate('/ecosystem/events')
//     }, 1)
//     return null
//   } else if (previewData.prismicTheCommunity) {
//     return <CommunityTemplate {...props} />
//   } else if (previewData.prismicSecurity) {
//     return <SecurityTemplate {...props} />
//   } else if (previewData.prismicVideos) {
//     return <VideosTemplate {...props} />
//   } else if (previewData.prismicBlockchain101) {
//     return <AboutBlockchainTemplate {...props} />
//   } else if (previewData.prismicEconomicInnovation) {
//     return <EconomicInnovationTemplate {...props} />
//   } else if (previewData.prismicEconomicInnovationArticle) {
//     return <EconomicInnovationArticleTemplate {...props} />
//   } else if (previewData.prismicTechOurApproach) {
//     return <OurApproachTemplate {...props} />
//   } else if (previewData.prismicTechCoreBlockchainInnovation) {
//     return <BlockchainInnovationTemplate {...props} />
//   } else if (previewData.prismicTechnologySubPage) {
//     return <TechnologySubPageTemplate {...props} />
//   } else if (previewData.prismicHomePage) {
//     return <HomepageTemplate {...props} />
//   } else if (previewData.prismicContactPage) {
//     return <ContactUsTemplate {...props} />
//   } else if (previewData.prismicEnterprise) {
//     return <EnterpriseContactTemplate {...props} />
//   } else if (previewData.prismicNewsletter) {
//     return <NewsletterSignupTemplate {...props} />
//   } else if (previewData.prismicWhyBuildOnAlgorand) {
//     return <WhyBuildOnAlgorandTemplate {...props} />
//   } else if (previewData.prismicTestnetTasksTelemetryRegistration) {
//     return <TestnetTasksTemplate {...props} />
//   } else if (previewData.prismicTaskSpendSomeMoney) {
//     return <TaskSpendTemplate {...props} />
//   } else if (previewData.prismicTechnology) {
//     return <TechnologyPageTemplate {...props} />
//   } else if (previewData.prismicTechOurApproach) {
//     return <TechnologyOurApproachTemplate {...props} />
//   } else if (previewData.prismicTechnicalAdvantages) {
//     return <TechnologyAdvantagesTemplate {...props} />
//   } else if (previewData.prismicUseCase) {
//     return <UseCaseTemplate {...props} />
//   } else if (previewData.prismicMediaKit) {
//     return <MediaKit {...props} />
//   } else {
//     console.log('Preview template not found', previewData)
//     return <h1 className="m-4">Preview not found</h1>
//   }
// }

// export default PreviewPage

import React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'

import linkResolver from '../linkResolver'

let previewUrlDisplayed = false

const PreviewPage = ({ pageContext, isPreview, isLoading }) => {
  const lang = pageContext?.locale || 'en-us'
  // Since previews are redirected immediately, we need to give editor a way
  // to copy url. The Prismic source plugin share preview urls aren't
  // currently working. see: https://github.com/angeloashmore/gatsby-source-prismic/issues/276#issuecomment-712526494
  if (typeof window !== 'undefined' && !previewUrlDisplayed) {
    previewUrlDisplayed = true
    window.prompt('Preview URL', window.document.location.href)
  }
  return (
    <div
      className="flex items-center justify-center"
      style={{ minHeight: '50vh' }}
    >
      <p className="text-2xl">Loading Preview...</p>
    </div>
  )
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'algorandcom',
  linkResolver,
})
